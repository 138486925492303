import * as React from "react"
import { useEffect } from "react"
import { Link } from "gatsby"

import Layout from "../../components/layout"
import Seo from "../../components/seo"

import ProjectHeader from "../../components/project-header/project-header"
import { RoughNotation } from "react-rough-notation"
import { labProjects } from "../../components/data/lab"
import { StaticImage } from "gatsby-plugin-image"

import { magnifierFollow } from "../../components/utils/magnifier"

const theme = labProjects['cryptobots'].themeColor;

const ProjectPage = () => {

  useEffect(()=>{
    magnifierFollow();
  });

  return (
  <>
    <Seo title="Bus Tracking App" />
    <ProjectHeader projectObj={labProjects['cryptobots']} />
    <section className={`fix-top`}>
      <div className={`content--md-center`}>
        <p className={`primary`}>The prompt was to create a character builder-like web interface that allows randomization of body parts to create new characters. The design had to feel game-like as new parts would unlock upon the completion of levels/challenges. Here are my illustrations of Cryptobots, the design solution to fit this prompt:</p>
        <p className={`primary`}>These designs were made using <RoughNotation type={`underline`} color={theme} show={true}>Figma</RoughNotation>.</p>
        <div className={`project-image`}>
          <StaticImage src={`../../images/projects/cryptobots/Together.jpg`} />
          <p className={`caption`}>Example Bots</p>
        </div>

        <div className={`project-image`}>
          <StaticImage src={`../../images/projects/cryptobots/Gems.png`} />
          <p className={`caption`}>Gems as type variants</p>
        </div>

        {/* <StaticImage src={`../../images/projects/cryptobots/Player - Grass Variants.jpg`} />
        <StaticImage src={`../../images/projects/cryptobots/Player - Fire Variants.jpg`} />
        <StaticImage src={`../../images/projects/cryptobots/Player - Electric Variants.jpg`} />
        <StaticImage src={`../../images/projects/cryptobots/Player - Water Variants.jpg`} />
        <StaticImage src={`../../images/projects/cryptobots/Player - Ice Variants.jpg`} />
        <StaticImage src={`../../images/projects/cryptobots/Player - Alien.jpg`} /> */}
        
        <div className={`project-image`}>
          <StaticImage src={`../../images/projects/cryptobots/Evolutions.jpg`} />
          <p className={`caption`}>Stages of building the bot</p>
        </div>

        <div className={`project-image hide-cursor`}>
          <div className={`magnifier-wrapper`}>
            <StaticImage
              src={'../../images/projects/cryptobots/Variants.jpg'}
              width={1200}
              quality={95}
              formats={["AUTO", "WEBP", "AVIF"]}
              alt="Affinity Map"
              loading={`eager`}
            />
            <div className={`magnifier`}>
              <div className={`mag-image-wrapper`}>
                <div className={`content--md`}>
                <StaticImage
                  src={'../../images/projects/cryptobots/Variants.jpg'}
                  width={2400}
                  quality={95}
                  formats={["AUTO", "WEBP", "AVIF"]}
                  alt="Affinity Map"
                  loading={`eager`}
                />
                </div>
              </div>
            </div>
          </div>
            <p className={`caption`}>Bot Variation Examples : Hover to Zoom</p>
        </div>

        <div className={`project-image`}>
          <StaticImage src={`../../images/projects/cryptobots/Game.jpg`} />
          <p className={`caption`}>Final Stage &mdash; Bot v/s Alien</p>
        </div>

        <div className={`center-content add-margin-top`}>
          <Link to='/lab'><button className={`data-hover`}>Back to Lab</button></Link>
        </div>
      </div>
    </section>
  </>
)
}

export default ProjectPage